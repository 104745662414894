<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { continueQuote, type ApiErrorResponse, isZixtyAxiosErrorCheck } from '@/utils/api'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import { datelocalised } from '@/utils/date'
import { DATE_FORMAT } from '@/constants'
import LoadingText from '@/components/LoadingText.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import type { PartnerResponse } from '@/utils/api-types'

const $route = useRoute()
const $router = useRouter()

useHead({
  title: `Retrieve Quote - Zixty`
})

const quote = useQuoteStore()

const errorsServer = ref<ApiErrorResponse | undefined>()
const inAjaxCall = ref<boolean>(false)

const correlation_id = $route.params.id as string

onMounted(async () => {
  inAjaxCall.value = true

  try {
    // call api and extract response
    const resp = await continueQuote(correlation_id)

    // update the expires at time
    quote.responses.quote_expires_at = resp?.headers['x-expiry-date'] || ''

    // normalise
    const data = resp?.data || {}
    const risk = data?.quote_params || {}
    const riskCoverStart = datelocalised(risk?.cover_start)
    const coverPeriodHours = Number((risk?.cover_period_hours || 0).toFixed(0))
    const isUnder24Hours = coverPeriodHours <= 24
    const isExpired = data?.status === 'EXPIRED'
    const partner = !!data?.partner ? (data?.partner as PartnerResponse) : null
    const isAggregator = !!data?.is_aggregator

    // update the correlation_id
    if (isExpired) {
      quote.responses.expired_correlation_id = correlation_id
    } else {
      quote.responses.correlation_id = correlation_id
    }

    // update the quote
    quote.inputs.address = risk?.address || ''
    quote.inputs.postcode = (risk?.address || '').split(',').pop()?.trim()
    quote.inputs.cover_start_date = riskCoverStart.format(DATE_FORMAT)
    quote.inputs.cover_start_hour = ('0' + riskCoverStart.hour()).slice(-2)
    quote.inputs.cover_start_minute = ('0' + riskCoverStart.minute()).slice(-2)
    quote.inputs.cover_start_date_time = riskCoverStart.toDate()
    quote.inputs.selected_duration = isUnder24Hours ? coverPeriodHours : coverPeriodHours / 24
    quote.inputs.selected_duration_unit = isUnder24Hours ? 'hours' : 'days'

    quote.inputs.email_address = risk?.email_address || ''
    quote.inputs.address = risk?.address || ''
    quote.inputs.postcode = (risk?.address || '').split(',').pop()?.trim()
    quote.inputs.cover_start_date = riskCoverStart.format(DATE_FORMAT)
    quote.inputs.cover_start_hour = ('0' + riskCoverStart.hour()).slice(-2)
    quote.inputs.cover_start_minute = ('0' + riskCoverStart.minute()).slice(-2)
    quote.inputs.cover_start_date_time = riskCoverStart.toDate()
    quote.inputs.selected_duration = isUnder24Hours ? coverPeriodHours : coverPeriodHours / 24
    quote.inputs.selected_duration_unit = isUnder24Hours ? 'hours' : 'days'
    quote.inputs.reason_for_cover = risk?.reason_for_cover || ''
    quote.inputs.reason_for_cover_own_vehicle = risk?.reason_for_cover_own_vehicle || ''
    quote.inputs.relation = risk?.relation || ''
    quote.inputs.type_approval = risk?.type_approval || ''
    quote.inputs.first_name = risk?.first_name || ''
    quote.inputs.last_name = risk?.last_name || ''
    quote.inputs.dlntype = risk?.licence_type || 'LICENCE_TYPE_FULL'
    quote.inputs.occupation = ('0' + risk?.occupation).slice(-3)
    quote.inputs.business_type = ('0' + risk?.business_type).slice(-3)
    quote.inputs.driving_licence_number = (risk?.driving_licence_number || '').replace(' ', '')
    quote.inputs.driving_licence_number_ui = risk?.driving_licence_number || ''

    if (quote.inputs.driving_licence_number && quote.inputs.driving_licence_number.length >= 16) {
      const dln = quote.inputs.driving_licence_number.slice(0, 16)
      quote.inputs.driving_licence_number_section_a = dln.slice(0, 5)
      quote.inputs.driving_licence_number_section_b = dln.slice(5, 11)
      quote.inputs.driving_licence_number_section_c = dln.slice(11, 12)
      quote.inputs.driving_licence_number_section_d = dln.slice(12, 16)
    }

    if (!!risk?.gender) {
      quote.inputs.gender = risk.gender
    } else {
      // get the gender from the dln
      if (!!quote.inputs.driving_licence_number_section_b) {
        const dlngender = quote.inputs.driving_licence_number_section_b.slice(1, 2)
        if (dlngender === '0' || dlngender === '1') {
          quote.inputs.gender = 'male'
        } else if (dlngender === '5' || dlngender === '6') {
          quote.inputs.gender = 'female'
        }
      }
    }

    quote.inputs.date_of_birth = datelocalised(risk?.date_of_birth, 'YYYY-MM-DD').toDate()
    quote.inputs.phone_number = (risk?.phone_number || '').replace(/^\+44/, 0)
    quote.inputs.phone_number_ui = quote.inputs.phone_number

    quote.inputs.registration_number = risk?.vehicle.registration_number
    quote.inputs.registration_number_ui = risk?.vehicle.registration_number

    quote.inputs.journey_type = 'direct'
    if (!!isAggregator) {
      quote.inputs.journey_type = 'aggregator'
    } else if (!!partner) {
      quote.inputs.journey_type = 'partner'
    }

    if (!!partner) {
      quote.responses.partner = partner
    }

    // update the vehicle details
    quote.responses.vehicle = risk?.vehicle || {}

    // set the brand
    quote.policy.brand = data?.brand || 'Zixty'

    // set the fields we need for
    quote.responses.fullquote = {
      quotes: {
        ...(data?.quotes_offered || {})
      }
    }

    // add the enumerations
    quote.responses.quickquote = {
      enumerations: data.enumerations,
      quote: {
        premium: `${quote?.policy?.total || '0'}`,
        InceptDateTime: riskCoverStart.toISOString(),
        ExpiryDateTime: datelocalised(quote?.policy?.selectedBrand?.ExpiryDateTime).toISOString()
      }
    }

    // set the addons
    quote.policy.addOns = (data?.add_on_codes || []).reduce(
      (acc: Record<string, boolean>, addOnCode: string) => {
        if (
          !!quote.policy.selectedBrand?.AddOns[addOnCode] &&
          !quote.policy.selectedBrand?.AddOns[addOnCode]?.Mandatory
        ) {
          acc = {
            ...acc,
            [addOnCode]: true
          }
        }
        return acc
      },
      {}
    )

    // track event
    if (quote.responses?.correlation_id) {
      quote.helpers.recordWebEvent('RETRIEVE_QUOTE')
    }

    // send the user to either the vehicle page if this is expired or to the quote page
    if (!!isExpired && !!partner && !isAggregator) {
      $router.push({
        name: 'partner.partner',
        params: {
          partner: partner.code
        },
        query: $route.query ? { ...$route.query } : {}
      })
    } else {
      const nextRouteName = isExpired ? 'quote.vehicle' : 'quote.quote'
      $router.push({
        name: nextRouteName,
        params: {
          journey: quote.inputs.journey_type
        },
        query: $route.query ? { ...$route.query } : {}
      })
    }
  } catch (error) {
    errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
  }

  inAjaxCall.value = false
})
</script>

<template>
  <div id="content" class="h-full flex px-3 py-3 md:px-6 md:py-6">
    <div class="w-full mx-auto">
      <div class="w-full mx-auto pt-6 md:pt-12 md:max-w-md md:-mb-5">
        <div class="py-3 md:py-6 text-center">
          <h2
            class="text-[28px] text-[#595959] font-medium flex flex-col justify-center items-center"
          >
            <span>We’re just</span>
            <LoadingText text="getting your quote" :dots="3"></LoadingText>
          </h2>
        </div>
        <FormServerErrors v-if="errorsServer" :error="errorsServer" />
        <div class="w-full">
          <form
            id="form-aggregator"
            novalidate
            :class="{
              'form23a-loading': inAjaxCall
            }"
          ></form>
        </div>
      </div>
    </div>
  </div>
</template>
