<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import { captureException as SentryCaptureException } from '@sentry/vue'
import { delay, getUtmParameters } from '@/utils/helpers'
import {
  VehicleSchema,
  ageMinimumJourneyAggregator,
  ageMinimumCarInYears,
  ageMinimumCommercialVehicleInYears,
  type VehicleSchemaErrorsType
} from '@/stores/quote.schema'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'
import LinkExternal from '@/components/LinkExternal.vue'
import LoadingText from '@/components/LoadingText.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import FormSpinner from '@/components/Quote/FormSpinner.vue'
import {
  getStart,
  getVehicle,
  isZixtyAxiosErrorCheck,
  type ApiErrorResponse,
  type StartResponse,
  type StartRequest
} from '@/utils/api'
import { datelocalised } from '@/utils/date'

const $route = useRoute()
const $router = useRouter()

useHead({
  title: 'Vehicle - Zixty Quote'
})

const quote = useQuoteStore()

quote.inputs.journey_type = ($route?.params?.journey || 'direct') as string

const incomingVrm = ref<string>('')
const haveFormErrors = computed(() => {
  return errorsClient.value?.fieldErrors?.registration_number || errorsServer.value?.code
})

const inAjaxCall = ref<boolean>(false)
const inOnStartAjaxCall = ref<boolean>(false)

const errorsClient = ref<VehicleSchemaErrorsType | undefined>()
const errorsServer = ref<ApiErrorResponse | undefined>()

const hasQuoteExpired = () => {
  return (
    !!quote.responses?.quote_expires_at &&
    datelocalised().unix() > datelocalised(quote.responses?.quote_expires_at).unix()
  )
}

const onStart = async (tries: number = 1): Promise<Partial<StartResponse>> => {
  inOnStartAjaxCall.value = true

  const onStartBeforeReturn = () => {
    inOnStartAjaxCall.value = false
  }

  try {
    if (!!quote.responses.correlation_id && hasQuoteExpired() === false) {
      onStartBeforeReturn()
      return {
        correlation_id: quote.responses.correlation_id,
        partner: quote.responses.partner
      }
    }

    // get the partner
    let partner = $route.params?.journey === 'direct' ? null : String($route.params?.journey || '')

    partner = partner === 'aggregator' ? null : partner

    // never allow 'partner' as a partner, if it is then look for the responses partner code
    // if it is not there then we are in a direct journey and we don't need to do anything
    partner = partner === 'partner' ? quote.responses?.partner?.code || null : partner

    // when partner is an empty string then we need to set it to null
    partner = partner === '' ? null : partner

    // build the getStartRequest
    let getStartRequest: StartRequest = {
      partner: partner
    }

    // add the old correlation id
    if (!!quote?.responses?.expired_correlation_id) {
      getStartRequest.expired_correlation_id = quote.responses.expired_correlation_id
    }

    // get utm params
    const utm_params = getUtmParameters($route)
    if (!!utm_params && Object.keys(utm_params).length > 0) {
      getStartRequest.utm = utm_params
    }

    // get the 'source_variation'
    getStartRequest.source_variation = $route.query?.source_variation
      ? String($route.query?.source_variation)
      : undefined

    const response = await getStart(getStartRequest)

    // build the response
    const data = response?.data || {}

    if (!data.correlation_id) {
      throw new Error('no correlation_id')
    }

    quote.responses.correlation_id = data.correlation_id
    quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''
    quote.responses.partner = data.partner

    quote.helpers.recordWebEvent('START_QUOTE')

    onStartBeforeReturn()

    return data
  } catch (error) {
    // don't retry this type of error
    if (
      isZixtyAxiosErrorCheck(error) &&
      (error.response?.data?.code === 'ZWS999' || error.response?.data?.code === 'ZWS9999')
    ) {
      onStartBeforeReturn()
      throw error
    }

    if (tries > 3) {
      SentryCaptureException(error)
      onStartBeforeReturn()
      throw error
    }

    await delay(tries * 1000)
    return await onStart(tries + 1)
  }
}

const getVehicleRequest = () => ({
  correlation_id: quote.responses.correlation_id || '',
  registration_number: quote.inputs.registration_number || ''
})

const onSubmit = async ($event?: Event) => {
  if (inAjaxCall.value) return
  inAjaxCall.value = true

  // hold up as we don't have the correlation_id... delay by 100ms then
  // call onSubmit again and return so this happens once
  if (inOnStartAjaxCall.value) {
    await delay(100)
    inAjaxCall.value = false
    onSubmit($event)
    return
  }

  quote.helpers.recordWebEvent('VEHICLE_SUBMITTED')

  // normalise the reg
  quote.inputs.registration_number_ui = (quote.inputs.registration_number_ui || '')
    .slice()
    .toUpperCase()

  quote.inputs.registration_number = (quote.inputs.registration_number_ui || '')
    .slice()
    .replace(/\s/g, '')

  // artificial delay
  await delay(25)

  // validate
  const safeParsed = VehicleSchema.safeParse({
    registration_number_ui: quote.inputs.registration_number_ui,
    registration_number: quote.inputs.registration_number
  })

  errorsClient.value = safeParsed.success ? undefined : safeParsed.error.flatten()
  errorsServer.value = undefined

  if (safeParsed.success) {
    try {
      // check again...
      const onStartResponse = await onStart()

      const response = await getVehicle(getVehicleRequest())

      quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''

      const data = response?.data || {}

      quote.responses.vehicle = data

      // used to validate the driver's age later on in the journey
      quote.inputs.type_approval = data.type_approval

      $router.push({
        name: 'quote.time'
      })
    } catch (error) {
      errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
    }
  }

  inAjaxCall.value = false
}

const redirectToDirectJourney = () => {
  return $router.replace({
    name: 'quote.vehicle',
    params: {
      journey: 'direct'
    }
  })
}

const removeSearchParam = (searchParamKey: string) => {
  const newQuery = { ...$route.query }
  try {
    delete newQuery[searchParamKey]
  } catch (err) {
    //
  }
  return newQuery
}

const getSearchParam = (searchParamKey: string) => {
  try {
    const searchParams = { ...$route.query }
    return searchParams?.[searchParamKey]
  } catch (err) {
    //
  }
}

onMounted(async () => {
  // redirect to partner page if we have ?partner=... and no quote.responses.partner entry
  if (!!$route?.query?.partner && !quote?.responses?.partner?.name) {
    return $router.replace({
      name: 'partner.partner',
      params: {
        partner: $route.query.partner as string
      }
    })
  }

  // redirect to direct if we have .../partner/... in the url but no partner object
  if (!quote.responses.partner?.name && $route.params.journey === 'partner') {
    return redirectToDirectJourney()
  }

  // redirect to direct if we have .../aggregator/... in the url but no aggregator response object
  if (!quote.responses.aggregator?.correlation_id && $route.params.journey === 'aggregator') {
    return redirectToDirectJourney()
  }

  try {
    // get the incoming vrm if we have one
    const vrmKey = 'vrm'
    const vrm = String(getSearchParam(vrmKey) || '')
    if (vrm?.length > 0) {
      incomingVrm.value = vrm
      quote.inputs.registration_number = vrm
      quote.inputs.registration_number_ui = vrm

      // remove the vrm from the url
      $router.replace({ query: removeSearchParam(vrmKey) })
    }

    // do the normal start of the quote
    const onStartResponse = await onStart()

    // click the submit button and if we have an error then show the form
    if (vrm?.length > 0) {
      onSubmit()
    }
  } catch (error) {
    errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
  }
})
</script>

<template>
  <div
    v-if="incomingVrm && !haveFormErrors"
    class="flex flex-wrap md:flex-nowrap justify-center items-center mx-auto"
  >
    <FormSpinner />
  </div>
  <div
    v-show="!incomingVrm || haveFormErrors"
    class="flex flex-wrap md:flex-nowrap justify-center items-center mx-auto"
  >
    <div class="w-full mx-auto pt-6 md:mx-0 md:pt-12 md:max-w-xs md:mr-4">
      <div class="py-3 md:py-6 flex justify-center md:justify-start text-center md:text-left">
        <QuoteFormTitle>Which car or van do you need cover for?</QuoteFormTitle>
      </div>

      <div v-if="quote.helpers.isCoverStartTimeHistorical() === true" class="my-3">
        <FormServerErrors
          :error="{
            code: '',
            message: '',
            what_to_do: '',
            what_to_say: `Please update the cover start time before continuing.`
          }"
        />
      </div>

      <FormServerErrors v-if="errorsServer" :error="errorsServer" />
      <form
        id="form-quote-vehicle"
        @submit.prevent="onSubmit"
        :class="{
          'form23a-loading': inAjaxCall
        }"
      >
        <FormSpinner v-if="inAjaxCall" />

        <div
          id="form-quote-vehicle--guideline"
          class="border border-blue-100 bg-blue-50 p-3 rounded-md my-3 mt-0 text-sm text-gray-500"
        >
          <p>
            Please note that we only insure cars from age
            {{
              quote.inputs.journey_type === 'aggregator'
                ? ageMinimumJourneyAggregator
                : ageMinimumCarInYears
            }}
            and vans from age {{ ageMinimumCommercialVehicleInYears }}. You need to have held a Full
            UK DVLA issued driving licence for 3+ months for car, or 12+ months for van. We also
            accept EU licences held for a minimum of 3 years on car only
          </p>
        </div>

        <div
          class="form23a-field-group mb-4"
          :class="{
            error:
              !!errorsClient?.fieldErrors?.registration_number ||
              !!errorsClient?.fieldErrors?.registration_number_ui
          }"
        >
          <label
            id="form-quote-vehicle--label-vehicle-registration"
            class="sr-only"
            for="vehicle_registration"
          >
            Enter vehicle registration...
          </label>
          <div class="w-full flex border-gray-100 bg-gray-100 p-3 rounded-md">
            <span
              class="bg-[#2457C5] w-10 rounded-md border-[#999797] border-r-0 rounded-r-none flex flex-wrap items-center justify-center p-1 text-center md:text-left"
            >
              <Image src="/images/static/uk-logo-small.png" class="w-full m-1" />
              <span class="text-white font-semibold">UK</span>
            </span>
            <input
              id="form-quote-vehicle--field-vehicle-registration"
              class="data-hj-allow form23a-input w-full border-l-0 rounded-l-none rounded-r-md uppercase"
              type="text"
              name="vehicle_registration"
              placeholder="Enter vehicle registration..."
              v-model="quote.inputs.registration_number_ui"
              v-capitalize-input
              aria-describedby="form-quote-vehicle--label-vehicle-registration"
            />
          </div>
          <QuoteFormFieldErrors
            :errors="
              errorsClient?.fieldErrors?.registration_number ||
              errorsClient?.fieldErrors?.registration_number_ui
            "
            id="vehicle_registration_field_error"
          />
        </div>
        <div>
          <p class="text-xs text-[#595959] text-center md:text-left mb-4">
            By continuing, you accept that you have read our
            <LinkExternal href="https://www.zixty.com/terms-and-conditions/">
              Terms &amp; Conditions
            </LinkExternal>
            and
            <LinkExternal href="https://www.zixty.com/privacy-policy/">Privacy Notice</LinkExternal
            >.
          </p>
        </div>
        <div class="py-3 md:py-6 w-full flex justify-center">
          <QuoteFormContinueButton
            type="submit"
            :deactivate="inAjaxCall"
            id="submit-vehicle-button"
          >
            <span v-if="inAjaxCall" class="flex items-center">
              <LoadingText text="Loading" :dots="0" />
            </span>
            <span v-else>Let's Go!</span>
          </QuoteFormContinueButton>
        </div>
      </form>
    </div>
    <div class="w-full md:ml-10">
      <Image src="/images/static/mini-and-van.png" class="max-h-[260px]" />
    </div>
  </div>
</template>
